import './assets/polyfills/index.js'
import './style/index.css';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import LandingPageData from './components/LandingPageData';
import { getRootDomain, getParameter, replaceSubdomain, removeURLParameter } from './lib/utils.js';
import Layout from './components/Layout';
import { updateRouteParams, loadTrackCookieData } from './actions';
import Survey from './routes/survey';

const domain = getRootDomain();


const GoRedirect = ({ history }) => {
  const navigate = useNavigate();
  let cl = decodeURIComponent((getParameter('cl') || "")).replace(/^\/+/, "");

  if (cl.indexOf('.') !== -1 || cl.indexOf('\\') !== -1) {
    cl = '';
  }

  useEffect(() => {
    if (domain === 'localhost' && cl !== "atredirect") {
      navigate(`/puretaboo/${cl}${window.location.search}`);
    }
    if (cl === "atredirect") {
      let newUrl = `https://www.iyalc.com/adulttime/go.php${window.location.search}`;
      newUrl = removeURLParameter(newUrl, "si");
      newUrl = removeURLParameter(newUrl, "cl");
      newUrl = removeURLParameter(newUrl, "cs");
      newUrl = removeURLParameter(newUrl, "disableRouting");
      newUrl += `&si=247&disableRouting=1`;
      window.location.href = newUrl;
      return null;
    }

    if (domain !== 'localhost') {
      if (getParameter('pa') === 'join') {
        //Replace subdomain with freetour from current url
        let newUrl = replaceSubdomain(window.location.href, "freetour");
        newUrl = removeURLParameter(newUrl, "cl");
        newUrl = removeURLParameter(newUrl, "cs");
        newUrl = removeURLParameter(newUrl, "disableRouting");

        //force redirection
        window.location.href = newUrl + '&disableRouting=1';
        return null;
      }
      else {
        navigate(`/${cl}${window.location.search}`);
      }
    }
  }, [cl, navigate]);

  return null;
};

const App = (props) => {

  const LandingPageDataWrapper = (props) => {
    const { slug, category, sitetag, domain } = props;
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(loadTrackCookieData());
      dispatch(updateRouteParams({ slug, category, sitetag, domain }));

    }, [slug, category, sitetag, domain, dispatch]);

    //if (!slug) return false;


    return useMemo(() => {
      return (<LandingPageData domain={domain} sitetag={sitetag} slug={slug}>
        <Layout />
      </LandingPageData>);
    }, [domain, sitetag, slug]);
  };




  const DomainRender = (props) => {
    let { slug = domain, category } = useParams() || {};
    return <LandingPageDataWrapper
      domain={domain}
      slug={slug}
      category={category}
    />;
  };

  const NetlifyRender = (props) => {
    let { sitetag = 'puretaboo', slug = 'default', category } = useParams() || {};

    return <LandingPageDataWrapper
      sitetag={sitetag}
      slug={slug}
      category={category}
    />;
  };

  return (

    <Routes>
      <Route path="/track/go/*" element={<GoRedirect />} />
      <Route path="/track/go.php/*" element={<GoRedirect />} />
      {(domain !== 'amazonaws.com' && domain !== 'pages.dev' && domain !== 'netlify.app' && domain !== 'localhost') &&
        <>
          <Route path="/survey/:surveyId?" element={<Survey domain={domain} />} />
          <Route path='/:slug?/:category?/*' element={<DomainRender />} />
        </>
      }
      <Route path="/:sitetag/survey/:surveyId?" element={<Survey />} />
      <Route path='/:sitetag?/:slug?/:category?/*' element={<NetlifyRender />} />
    </Routes>

  );

};

export default App;
