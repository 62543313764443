import React from 'react';
import styled from 'styled-components';
import useTracking from '../Tracking/useTracking';

const SocialIconsWrapper = styled.div`
    padding: 20px 20px 40px;
`;

const Logo = styled.a`
    background: #fff;
    background-image: url( ${p => p.src});
    font-size: 0;
    transition: background .3s;
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 0 11px;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: 50%;
    
    @media (max-width: 995px){
        background-size: 38px;
        width: 50px;
        height: 50px;
        margin: 0 15px;
    }
`;

const SocialIcons = ({
    instagramSrc = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDE2OS4xIDE2OS4xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNjkuMSAxNjkuMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8cGF0aCBkPSJNMTEzLjEsODQuNWMwLDE1LjctMTIuOCwyOC42LTI4LjYsMjguNlM1NiwxMDAuMyw1Niw4NC41QzU2LDY4LjgsNjguOCw1Niw4NC41LDU2UzExMy4xLDY4LjgsMTEzLjEsODQuNXogTTE1NCwxMjIuNAoJTDE1NCwxMjIuNGMwLDE3LjQtMTQuMiwzMS42LTMxLjYsMzEuNkg0Ni43QzI5LjIsMTU0LDE1LDEzOS44LDE1LDEyMi40VjQ2LjdDMTUsMjkuMiwyOS4yLDE1LDQ2LjcsMTVoNzUuNwoJYzE3LjQsMCwzMS42LDE0LjIsMzEuNiwzMS42VjEyMi40eiBNMTI4LjEsODQuNWMwLTI0LTE5LjUtNDMuNS00My41LTQzLjVTNDEsNjAuNSw0MSw4NC41YzAsMjQsMTkuNSw0My41LDQzLjUsNDMuNQoJUzEyOC4xLDEwOC41LDEyOC4xLDg0LjV6IE0xNDAuOSwzOS4zYzAtMi45LTEuMi01LjctMy4yLTcuOGMtMi0yLTQuOS0zLjItNy44LTMuMmMtMi45LDAtNS43LDEuMi03LjgsMy4yYy0yLjEsMi0zLjIsNC45LTMuMiw3LjgKCWMwLDIuOSwxLjIsNS43LDMuMiw3LjhjMiwyLDQuOSwzLjIsNy44LDMuMmMyLjksMCw1LjctMS4yLDcuOC0zLjJDMTM5LjcsNDUsMTQwLjksNDIuMiwxNDAuOSwzOS4zeiIvPgo8L3N2Zz4K',
    twitterSrc = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iQ2FscXVlXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMTAwMCAxMDAwIj4KICA8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMjkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogMi4xLjAgQnVpbGQgMTQyKSAgLS0+CiAgPHBhdGggZD0iTTgwNi4zLDEwSDE5My44QzkyLjMsMTAsMTAsOTIuMywxMCwxOTMuOHY2MTIuNWMwLDEwMS40LDgyLjMsMTgzLjcsMTgzLjgsMTgzLjdoNjEyLjVjMTAxLjQsMCwxODMuNy04Mi4zLDE4My43LTE4My43VjE5My44YzAtMTAxLjUtODIuMy0xODMuOC0xODMuNy0xODMuOFpNNjI5LjEsODEwLjRsLTE2MC4zLTIzMy40LTIwMC44LDIzMy40aC01MS45bDIyOS42LTI2Ni45TDIxNi4xLDIwOS40aDE3NS4xbDE1MS44LDIyMSwxOTAuMS0yMjFoNTEuOWwtMjE4LjksMjU0LjVoMHMyMzguMSwzNDYuNSwyMzguMSwzNDYuNWgtMTc1LjFaIi8+CiAgPHBvbHlnb24gcG9pbnRzPSI1MTUuNyA0NjIuMSAzNjYuMyAyNDguNCAyODYuNiAyNDguNCA0NzEuNyA1MTMuMiA0OTUgNTQ2LjUgNDk1LjEgNTQ2LjUgNjUzLjUgNzczLjEgNzMzLjIgNzczLjEgNTM5IDQ5NS40IDUxNS43IDQ2Mi4xIi8+Cjwvc3ZnPg==',
    youtubeSrc = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZD0iTTU0LjIsMTRsNDAxLjcsMjQxTDU0LjIsNDk2VjE0eiIvPgo8L3N2Zz4K',
    twitterLink = 'https: //twitter.com/Adulttimecom/',
    instagramLink = 'https://www.instagram.com/adulttimecom/',
    youtubeLink = 'https://www.youtube.com/AdultTimeCom',
    twitterTitle = '',
    instagramTitle = '',
    youtubeTitle = ''

}) => {
    const { trackAndRedirect } = useTracking();

    const handleClick = e => {
        e.stopPropagation();
        e.preventDefault();
        let payload = { component: 'SocialIcons', href: e.target.href };
        trackAndRedirect(payload, e.target.href, '_blank');
    };

    return (
        <SocialIconsWrapper>
            {twitterLink && <Logo href={twitterLink} title={twitterTitle} onClick={handleClick} src={twitterSrc}>Twitter</Logo>}
            {instagramLink && <Logo href={instagramLink} title={instagramTitle} onClick={handleClick} src={instagramSrc} style={{ borderRadius: '15px' }}>Instagram</Logo>}
            {youtubeLink && <Logo href={youtubeLink} title={youtubeTitle} onClick={handleClick} src={youtubeSrc} style={{ backgroundSize: '25px' }}>Youtube</Logo>}
        </SocialIconsWrapper>
    );
};

export default SocialIcons;
